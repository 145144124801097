<template>
    <div class="pl-2">
      <v-chip small>
        <v-icon small :color="priorityColor" left>mdi-flag-outline</v-icon>
        {{ priorityName }}
      </v-chip>
    </div>
  </template>

  <script>
  export default {
    name: "TaskPriority",

    props: {
      priorityName: {
        type: String,
        required: true,
      },
      priorityColor: {
        type: String,
        required: true,
      },
    },
  }
  </script>
