<!--
.. Modified by Kishore Jalleda
.. full list of modifications at https://github.com/unstructai
.. copyright: (c) 2023 Kishore Jalleda
.. author:: Kishore Jalleda <kjalleda@gmail.com>
-->
<template>
    <div class="pl-2">
      <v-chip small :color="statusColor">
        <v-icon small left>mdi-list-status</v-icon>
        {{ statusName }}
      </v-chip>
    </div>
  </template>

  <script>
  export default {
    name: "TaskStatus",

    props: {
      statusName: {
        type: String,
        required: true,
      },
      statusColor: {
        type: String,
        required: true,
      },
    },
  }
  </script>
