<!--
.. Modified by Kishore Jalleda
.. full list of modifications at https://github.com/unstructai
.. copyright: (c) 2023 Kishore Jalleda
.. author:: Kishore Jalleda <kjalleda@gmail.com>
-->
<template>
  <v-container fluid>
    <div v-if="showEditSheet">
      <router-view />
    </div>
    <v-row>
      <v-col class="mb-2">
        <div class="headline">
          <v-icon left large>mdi-file-tree</v-icon>
          IAO Tasks
        </div>
      </v-col>
      <v-col cols="1" class="text-center" v-for="status in statuses" :key="status.value">
        <v-chip
          small
          :color="status.color"
          :outlined="currentStatus !== status.value"
          @click="setCurrentStatus(status.value)"
          class="ma-2"
        >
          <v-icon small left>{{ status.icon }}</v-icon>
          {{ status.label }}
        </v-chip>
      </v-col>
      <v-col class="text-right
      ">
        <table-filter-dialog :projects="defaultUserProjects" />
        <table-export-dialog />
        <v-btn small color="info" class="ml-2" @click="showNewSheet()">
          <v-icon small>mdi-plus</v-icon>
          New Task
        </v-btn>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <new-sheet />
      <delete-dialog />
    </v-row>
    <v-row no-gutters>
      <v-expansion-panels popout>
      <v-col cols="12" v-for="item in items" :key="item.id">
        <v-expansion-panel>
          <v-expansion-panel-header>
            <span>
              <v-icon small left color="primary">mdi-domain</v-icon>
              {{ item.incident.name }}
            </span>
            <v-spacer />
            <task-status :statusName="item.status" :statusColor="getTaskStatusColorClass(item)" />
            <v-chip v-if="!item.resolved_at" class="ml-2">
              <v-icon :color="getTaskStatusColorClass(item)" left>mdi-timer-sand</v-icon>
              {{ item.resolve_by | formatRelativeDate }}
            </v-chip>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-card outlined class="pa-1 ml-3 mr-2 md-2">
              <v-card-title>
                <v-icon small left>mdi-domain</v-icon>
                {{ item.incident.name }}
                <task-status :statusName="item.status" :statusColor="getTaskStatusColorClass(item)" />
                <v-chip v-if="!item.resolved_at" class="ml-2">
                  <v-icon color="primary" left>mdi-clock-outline</v-icon>
                  {{ item.resolve_by | formatRelativeDate }}
                </v-chip>
                <v-chip small v-if="isOverdue(item)"
                        :disabled="item.status === 'Resolved'"
                        class="mr-2 ml-2"
                >
                  <v-icon small color="warning" left>mdi-alert-circle</v-icon>
                  Overdue
                </v-chip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn color="secondary" icon v-bind="attrs" v-on="on" @click="showTaskEditSheet(item)">
                      <v-icon>mdi-book-edit-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>View/Edit</span>
                </v-tooltip>
              </v-card-title>
              <v-card-subtitle>
                <div>
                  <strong>Creator:</strong> <participant :participant="item.creator" />
                </div>
                <div class="mb-2">
                  <strong>Owner:</strong> <participant :participant="item.owner" />
                </div>
                <div>
                  <strong>Project:</strong>
                  <project-card :project="item.project" />
                </div>
              </v-card-subtitle>
              <v-card-text>
                <div>
                  <v-icon small left>mdi-axis-arrow-info</v-icon>
                  <strong>Description:</strong> {{ item.description }}
                </div>
                <div>
                  <v-icon small left>mdi-source-branch</v-icon>
                  <strong>Resolve by:</strong> {{ item.resolve_by | formatRelativeDate }}
                </div>
                <div v-if="item.resolved_at">
                  <v-icon small left>mdi-lock-outline</v-icon>
                  <strong>Resolved at:</strong> {{ item.resolved_at | formatRelativeDate }}
                </div>
                <div v-else>
                  <v-icon small left>mdi-lock-open-outline</v-icon>
                  <strong>Resolved at:</strong> Ongoing
                </div>
                <div class="mb-2 mt-2">
                  <strong>BAO Priority:</strong>
                  <incident-priority
                    :priorityName="item.incident.incident_priority.name"
                    :priorityColor="item.incident.incident_priority.color"
                  />
                </div>
                <div class="mb-2">
                  <strong>Task Priority:</strong>
                  <task-priority :priorityName="item.priority" :priorityColor="getColor(item.priority)" />
                </div>
                <div v-if="item.assignees.length" class="mb-2">
                  <strong>Assignees:</strong>
                  <div class="mb-1" v-for="assignee in item.assignees" :key="assignee.id">
                    <participant :participant="assignee" />
                  </div>
                </div>
                <div v-if="item.incident" class="mt-2">
                  <strong>BAO:</strong>
                  <v-list-item :to="{ name: 'IncidentTableEdit', params: { name: item.incident.name }}">
                    <v-icon small left color="info">mdi-domain</v-icon>
                    {{ item.incident.name }}
                    <v-icon right small color="primary">mdi-open-in-new</v-icon>
                  </v-list-item>
                </div>
              </v-card-text>
              <v-card-actions class="justify-end">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn color="secondary" icon v-bind="attrs" v-on="on" @click="showTaskEditSheet(item)">
                      <v-icon>mdi-book-edit-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>View/Edit</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn icon
                      @click="markResolved(item.id)"
                      :disabled="item.status === 'Resolved'"
                      :loading="updateLoading"
                      v-on="on">
                      <v-icon color="success">mdi-lock-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>Mark Resolved</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn icon
                      @click="markOpen(item.id)"
                      :disabled="item.status === 'Open'"
                      :loading="updateLoading"
                      v-on="on">
                      <v-icon color="info">mdi-lock-open-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>Mark Open</span>
                </v-tooltip>
                <v-menu bottom left>
                  <template v-slot:activator="{ on }">
                    <v-btn icon v-on="on">
                      <v-icon color="primary">mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item
                      :to="{
                        name: 'TaskTableEdit',
                        params: { id: item.id }
                        }"
                      >
                        <v-list-item-title>
                          <v-icon left>mdi-book-edit-outline</v-icon>Edit
                        </v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="removeShow(item)">
                      <v-list-item-title>
                        <v-icon left>mdi-delete</v-icon>Delete
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-card-actions>
            </v-card>
      </v-expansion-panel-content>
        </v-expansion-panel>
      </v-col>
    </v-expansion-panels>
    </v-row>


    <v-row no-gutters>
      <v-col>
        <v-card elevation="0">
          <v-card-title>
            <v-text-field v-model="q" append-icon="search" label="Search" single-line hide-details clearable />
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="items"
            :server-items-length="total"
            :page.sync="page"
            :items-per-page.sync="itemsPerPage"
            :sort-by.sync="sortBy"
            :sort-desc.sync="descending" v-model="selected"
            :loading="loading" loading-text="Loading... Please wait"
            :footer-props="{
              'items-per-page-options': [10, 25, 50, 100],
            }"
            show-select
            @click:row="showTaskEditSheet">
            <template v-slot:item.description="{ item }">
              <div class="text-truncate" style="max-width: 400px">
                {{ item.description }}
              </div>
            </template>
            <template v-slot:item.project.name="{ item }">
              <project-card :project="item.project" />
            </template>
            <template v-slot:item.incident_priority.name="{ item }">
              <incident-priority
                :priorityName="item.incident.incident_priority.name"
                :priorityColor="item.incident.incident_priority.color"
              />
            </template>
            <template v-slot:item.creator.individual_contact.name="{ item }">
              <participant :participant="item.creator" />
            </template>
            <template v-slot:item.owner.individual_contact.name="{ item }">
              <participant :participant="item.owner" />
            </template>
            <template v-slot:item.incident_type.name="{ item }">
              {{ item.incident.incident_type.name }}
            </template>
            <template v-slot:item.priority="{ item }">
              <task-priority :priorityName="item.priority" :priorityColor="getColor(item.priority)" />
            </template>
            <template v-slot:item.status="{ item }">
              <task-status :statusName="item.status" :statusColor="getTaskStatusColorClass(item)" />
            </template>
            <template v-slot:item.assignees="{ item }">
              <participant v-for="assignee in item.assignees" :key="assignee.id" :participant="assignee" />
            </template>
            <template v-slot:item.resolve_by="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on">{{ item.resolve_by | formatRelativeDate }}</span>
                </template>
                <span>{{ item.resolve_by | formatDate }}</span>
              </v-tooltip>
            </template>
            <template v-slot:item.created_at="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on">{{ item.created_at | formatRelativeDate }}</span>
                </template>
                <span>{{ item.created_at | formatDate }}</span>
              </v-tooltip>
            </template>
            <template v-slot:item.resolved_at="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on">{{ item.resolved_at | formatRelativeDate }}</span>
                </template>
                <span>{{ item.resolved_at | formatDate }}</span>
              </v-tooltip>
            </template>
            <template v-slot:item.source="{ item }">
              <a :href="item.weblink" target="_blank" style="text-decoration: none">
                {{ item.source }}
                <v-icon small>open_in_new</v-icon>
              </a>
            </template>
            <template v-slot:item.data-table-actions="{ item }">
              <v-menu bottom left>
                <template v-slot:activator="{ on }">
                  <v-btn icon v-on="on">
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>
                <v-list>
                    <v-list-item
                    :to="{
                      name: 'TaskTableEdit',
                      params: { id: item.id },
                    }"
                  >
                    <v-list-item-title>View / Edit</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <bulk-edit-sheet />
  </v-container>
</template>

<script>
import { mapFields } from "vuex-map-fields"
import { mapActions } from "vuex"

import BulkEditSheet from "@/task/BulkEditSheet.vue"
import DeleteDialog from "@/task/DeleteDialog.vue"
import IncidentPriority from "@/incident/priority/IncidentPriority.vue"
import NewEditSheet from "@/task/NewEditSheet.vue"
import NewSheet from "@/task/NewSheet.vue"
import Participant from "@/incident/Participant.vue"
import RouterUtils from "@/router/utils"
import TableExportDialog from "@/task/TableExportDialog.vue"
import TableFilterDialog from "@/task/TableFilterDialog.vue"
import TaskPriority from "@/task/TaskPriority.vue"
import TaskStatus from "@/task/TaskStatus.vue"
import ProjectCard from "@/project/ProjectCard.vue"

export default {
  name: "TaskTable",

  components: {
    BulkEditSheet,
    DeleteDialog,
    IncidentPriority,
    NewEditSheet,
    NewSheet,
    Participant,
    TableExportDialog,
    TableFilterDialog,
    TaskPriority,
    TaskStatus,
    ProjectCard
  },

  data() {
    return {
      headers: [
        { text: "BAO", value: "incident.name", sortable: true },
        { text: "BAO Priority", value: "incident_priority.name", sortable: true },
        { text: "BAO Type", value: "incident_type.name", sortable: true },
        { text: "Status", value: "status", sortable: true },
        { text: "Task Priority", value: "priority", sortable: true},
        { text: "Task Type", value: "type", sortable: true},
        { text: "Creator", value: "creator.individual_contact.name", sortable: true },
        { text: "Owner", value: "owner.individual_contact.name", sortable: true },
        { text: "Assignees", value: "assignees", sortable: false },
        { text: "Description", value: "description", sortable: false },
        { text: "Source", value: "source", sortable: true },
        { text: "Project", value: "project.name", sortable: true },
        { text: "Due By", value: "resolve_by", sortable: true },
        { text: "Created At", value: "created_at", sortable: true },
        { text: "Resolved At", value: "resolved_at", sortable: true },
        { text: "", value: "data-table-actions", sortable: false, align: "end" },
      ],
      showEditSheet: false,
      statuses: [
        { value: "Open", label: "Open", icon: "mdi-lock-open-outline", color: "error" },
        { value: "Resolved", label: "Resolved", icon: "mdi-lock-outline", color: "success" },
        { value: "All", label: "All", icon: "mdi-filter", color: "primary"}
      ],
      currentStatus: "All",
    }
  },

  computed: {
    ...mapFields("task", [
      "table.options",
      "table.options.q",
      "table.options.page",
      "table.options.itemsPerPage",
      "table.options.sortBy",
      "table.options.descending",
      "table.options.filters",
      "table.options.filters.creator",
      "table.options.filters.assignee",
      "table.options.filters.incident",
      "table.options.filters.incident_type",
      "table.options.filters.incident_priority",
      "table.options.filters.status",
      "table.options.filters.type",
      "table.options.filters.priority",
      "table.options.filters.project",
      "table.loading",
      "table.rows.items",
      "table.rows.total",
      "table.rows.selected",
      "updateLoading",
    ]),
    ...mapFields("route", ["query"]),
    ...mapFields("auth", ["currentUser.projects"]),

    defaultUserProjects: {
      get() {
        let d = null
        if (this.projects) {
          let d = this.projects.filter((v) => v.default === true)
          return d.map((v) => v.project)
        }
        return d
      },
    },
  },

  methods: {
    ...mapActions("task", ["getAll", "showNewSheet", "removeShow", "markResolved", "markOpen"]),
    setCurrentStatus(status) {
      this.currentStatus = status
      if (status === "All") {
        this.filters.status = null
        return
      }
      this.filters.status = [status]
    },
    showTaskEditSheet(item) {
      this.$router.push({ name: "TaskTableEdit", params: { id: item.id } })
    },
    getColor(priorityName) {
      switch (priorityName) {
        case 'Low':
          return 'success';
        case 'Medium':
          return 'warning';
        case 'High':
          return 'red';
        default:
          return 'grey';
      }
    },
    getStatusColor(status) {
      switch (status) {
        case 'Resolved':
          return 'success';
        case 'Open':
          return 'warning';
        default:
          return 'grey';
      }
    },
    // If open not not overdue, return primary, if overdue return red, if resolved return success
    getTaskStatusColorClass(task) {
      const dueDate = task.resolve_by ? new Date(task.resolve_by) : null;
      const currentDate = new Date();
      if (task.status !== 'Resolved' && dueDate && currentDate > dueDate) {
          return "warning";
      } else if (task.status === 'Resolved') {
          return "success";
      } else {
          return "primary";
      }
    },
    // also check if the task is resolved, if so, return not overdue.
    isOverdue(task) {
      const dueDate = task.resolve_by ? new Date(task.resolve_by) : null;
      const currentDate = new Date();
      return dueDate && currentDate > dueDate;
    },
    // Due in minutes, or hours or days
    dueIn(task) {
      const dueDate = task.resolve_by ? new Date(task.resolve_by) : null;
      const currentDate = new Date();
      if (dueDate) {
        const diff = dueDate - currentDate;
        const minutes = Math.floor((diff / (1000 * 60)) % 60);
        const hours = Math.floor((diff / (1000 * 60 * 60)) % 24);
        const days = Math.floor(diff / (1000 * 60 * 60 * 24));
        if (days > 0) {
          return `${days} days`;
        } else if (hours > 0) {
          return `${hours} hours`;
        } else {
          return `${minutes} minutes`;
        }
      }
      return null;
    },
  },

  watch: {
    $route: {
      immediate: true,
      handler: function (newVal) {
        this.showEditSheet = newVal.meta && newVal.meta.showEditSheet
      },
    },
  },

  created() {
    this.filters = {
      ...this.filters,
      ...RouterUtils.deserializeFilters(this.query),
      project: this.defaultUserProjects,
    }

    this.getAll()

    this.$watch(
      (vm) => [vm.page],
      () => {
        this.getAll()
      }
    )

    this.$watch(
      (vm) => [
        vm.q,
        vm.itemsPerPage,
        vm.sortBy,
        vm.descending,
        vm.project,
        vm.incident,
        vm.incident_type,
        vm.incident_priority,
        vm.status,
        vm.type,
        vm.priority,
      ],
      () => {
        this.page = 1
        RouterUtils.updateURLFilters(this.filters)
        console.log(this.filters)
        this.getAll()
      }
    )
  },
}
</script>
