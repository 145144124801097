<!--
.. Added by Kishore Jalleda
.. full list of modifications at https://github.com/unstructai
.. copyright: (c) 2023 Kishore Jalleda
.. author:: Kishore Jalleda <kjalleda@gmail.com>
-->
<template>
    <ValidationObserver v-slot="{ invalid, validated }">
      <v-navigation-drawer
      v-model="showNewSheet"
      app
      clipped
      right
      width="800"
      :permanent="$vuetify.breakpoint.mdAndDown"
    >
        <template v-slot:prepend>
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title v-if="id" class="title"> Edit </v-list-item-title>
              <v-list-item-title v-else class="title"> New </v-list-item-title>
              <v-list-item-subtitle>Task</v-list-item-subtitle>
            </v-list-item-content>
            <v-btn
              icon
              color="info"
              :loading="loading"
              :disabled="invalid || !validated"
              @click="save()"
            >
              <v-icon>save</v-icon>
            </v-btn>
            <v-btn icon color="secondary" @click="closeNewSheet">
            <v-icon>close</v-icon>
          </v-btn>
          </v-list-item>
        </template>
        <v-card flat>
          <v-card-text>
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex xs12>
                  <ValidationProvider name="description" immediate>
                    <v-textarea
                      v-model="description"
                      slot-scope="{ errors, valid }"
                      label="Description"
                      :error-messages="errors"
                      :success="valid"
                      hint="The task's description."
                      clearable
                      required
                    />
                  </ValidationProvider>
                </v-flex>
                <v-flex xs12>
                  <project-select v-model="project" />
                </v-flex>
                <v-flex xs12>
                  <v-select
                    v-model="priority"
                    label="Priority"
                    :items="priorities"
                    hint="The tasks current priority"
                  />
                </v-flex>
                <v-flex xs12>
                  <v-select
                    v-model="status"
                    label="Status"
                    :items="statuses"
                    hint="The incident's current status"
                  />
                </v-flex>
                <v-flex xs12>
                  <v-select
                    v-model="type"
                    label="Type"
                    :items="types"
                    hint="The tasks current type"
                  />
                </v-flex>
                <v-flex xs12>
                  <ValidationProvider name="incident" rules="required" immediate>
                    <incident-select
                      v-model="incident"
                      slot-scope="{ errors, valid }"
                      label="Incident"
                      :error-messages="errors"
                      :success="valid"
                      hint="The tasks associated incident"
                      clearable
                      required
                    />
                  </ValidationProvider>
                </v-flex>
                <v-flex xs12>
                  <ValidationProvider name="owner" rules="required" immediate>
                    <participant-select
                      v-model="owner"
                      slot-scope="{ errors, valid }"
                      label="Owner"
                      :error-messages="errors"
                      :success="valid"
                      hint="The tasks current owner"
                      clearable
                      required
                    />
                  </ValidationProvider>
                </v-flex>
                <v-flex xs12>
                  <ValidationProvider name="assignees" rules="required" immediate>
                    <assignee-combobox
                      v-model="assignees"
                      slot-scope="{ errors, valid }"
                      label="Assignees"
                      :error-messages="errors"
                      :success="valid"
                      hint="The tasks current assignees"
                      clearable
                      required
                    />
                  </ValidationProvider>
                </v-flex>
                <v-flex xs12>
                  <v-row>
                    <v-col cols="6">
                      <date-time-picker-menu label="Resolved At" v-model="resolved_at" />
                    </v-col>
                    <v-col cols="6">
                      <date-time-picker-menu label="Resolve By" v-model="resolve_by" />
                    </v-col>
                  </v-row>
                </v-flex>
                <v-flex xs12>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <div v-bind="attrs" v-on="on">
                        <v-checkbox
                          v-model="reminders"
                          label="Reminders"
                        />
                      </div>
                    </template>
                    <span>Reminders will be sent for stable and active incidents. After the first reminder past the due date, reminders will be sent once a day.</span>
                  </v-tooltip>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>
        </v-card>
      </v-navigation-drawer>
    </ValidationObserver>
  </template>

  <script>
  import { mapFields } from "vuex-map-fields"
  import { mapActions } from "vuex"
  import { ValidationObserver, ValidationProvider, extend } from "vee-validate"
  import ProjectSelect from "@/project/ProjectSelect.vue"
  import IncidentSelect from "@/incident/IncidentSelect.vue"
  import ParticipantSelect from "@/incident/ParticipantSelect.vue"
  import AssigneeCombobox from "@/task/AssigneeCombobox.vue"
  import DateTimePickerMenu from "@/components/DateTimePickerMenu.vue"
  import TaskTypeMultiSelect from "@/task/TaskTypeMultiSelect.vue"
  import { required } from "vee-validate/dist/rules"

  extend("required", {
    ...required,
    message: "This field is required",
  })

  export default {
    name: "TaskNewSheet",

    components: {
      ValidationObserver,
      ValidationProvider,
      IncidentSelect,
      AssigneeCombobox,
      ParticipantSelect,
      ProjectSelect,
      DateTimePickerMenu,
      TaskTypeMultiSelect,
    },

    data() {
      return {
        statuses: ["Open", "Resolved"],
        priorities: ["Low", "Medium", "High"],
        types: ["Task", "Follow-up"],
      }
    },

    computed: {
      ...mapFields("task", [
        "selected.status",
        "selected.type",
        "selected.priority",
        "selected.owner",
        "selected.assignees",
        "selected.description",
        "selected.creator",
        "selected.project",
        "selected.reminders",
        "selected.resolved_at",
        "selected.resolve_by",
        "selected.incident",
        "selected.loading",
        "selected.id",
        "dialogs.showCreateEdit",
        "dialogs.showNewSheet",
      ]),
      ...mapFields("route", ["query"]),
    },

    methods: {
      fetchDetails() {
        this.getDetails({ id: this.$route.params.id })
      },
      ...mapActions("task", ["save", "closeCreateEdit", "getDetails", "closeNewSheet"]),
    },

    watch: {
      "$route.params.id": function () {
        this.fetchDetails()
      },
    },

    created() {
      if (this.query.project) {
        this.project = { name: this.query.project }
      }
      this.fetchDetails()
    },
  }
  </script>
